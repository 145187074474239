import {
  ansible,
  aws,
  circleci,
  docker,
  git,
  github,
  githubActions,
  gitlab,
  go,
  helm,
  kubernetes,
  linux,
  nginx,
  prometheus,
  python,
  sentry,
  serverless,
  terraform,
} from "components/StackItems";

export default [
  ansible,
  aws,
  circleci,
  docker,
  git,
  github,
  githubActions,
  gitlab,
  go,
  helm,
  kubernetes,
  linux,
  nginx,
  prometheus,
  python,
  sentry,
  serverless,
  terraform,
];
